<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2 col-6">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Mill" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="typeModel" :items="typeLists" default="" item-value="value" item-text="text" label="Type" dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" :label=labelRegion clearable dense :disabled="disabled_region == true"  @change="(event) => regionOnChange(event)" prepend-icon="mdi-city"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" :label=labelBranch clearable dense :disabled="disabled_office == true"  @change="(event) => officeOnChange(event)" prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" :label=labelSales clearable dense :disabled="disabled_salesman == true" prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-autocomplete v-model="customerModel" :items="customerLists" default="" item-value="CustomerId" :item-text="item => item.CustomerId +' - '+ item.NamaCustomer" label="Enter Customer Name/ID" clearable dense prepend-icon="mdi-account-multiple" @keyup.enter="updateCustomerDropdown()" @change="(event) => emptyCustomer(event)" @mouseenter="(event) => emptyCustomer(event)" id="customer">
                                    <template v-slot:no-data><v-list-item>
                                        <v-list-item-title>Put <strong>Customer Name/ID</strong>, then press enter</v-list-item-title></v-list-item>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="categoryGroupModel" :items="categoryGroupLists" default="" item-value="cat_group" item-text="cat_group" label="Category Group" @change="(event) => categoryGroupOnChange(event)" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="categoryModel" :items="categoryLists" default="" item-value="category_id" item-text="cat_desc" label="Category" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="widthModel" :items="widthLists" default="" item-value="width" :item-text="item => item.width + ' mm'" label="Width" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="materialModel" :items="materialLists" default="" item-value="jenis" item-text="jenis" label="Material" clearable dense prepend-icon="mdi-widgets"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="gradeModel" :items="gradeLists" default="" item-value="grade_id" item-text="grade_id" label="Grade" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="qualityModel" :items="qualityLists" default="" item-value="quality_id" item-text="quality_id" label="Quality" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="colorModel" :items="colorLists" default="" item-value="KodeWarna" item-text="NamaWarna" label="Color" clearable dense prepend-icon="mdi-set-right"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="thicknessModel" :items="thicknessLists" default="" item-value="thick" :item-text="item => item.thick + ' mm'" label="Thickness" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-12 text-right">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">Result</h5>
                        <v-col cols="12" xs="12" sm="12" md="3">
                                    <div style="width: 50%;">
                                        <v-sheet
                                            class="mx-auto"
                                            >
                                            <v-card class="p-0 m-0 red darken-4">
                                                <v-card-text class="p-3 red darken-4" >
                                                    <v-row>
                                                        <v-col cols="12 text-white  text-center pl-1 pr-1 pb-1">
                                                            <h7 v-if="totalWgt"> Total WGT  : {{ $store.getters.convertToCurrencyUs(totalWgt) }} TON</h7>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-sheet>
                                    </div>
                                </v-col>
                                <v-btn
                                small
                                color="#005c37"
                                class="py-5 mr-3 text-white"
                                >
                                <v-btn class="mb-0 mr-2 text-white" 
                                color="#005c37" elevation="2" medium>
                                <download-excel
                                class="text"
                                :fetch           = "exportExcel"
                                :fields="headersColumn"
                                :before-generate = "startDownload"
                                :before-finish   = "finishDownload">
                                To Excel
                                </download-excel> 
                                <v-icon>mdi-arrow-down-bold-box</v-icon>
                            </v-btn>
                                </v-btn>
                                    <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-25">
                                            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                        </v-data-table>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'E-Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Order Shortage Report',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            totalWgt:[],
            typeModel: '',
            typeLists: [],
            labelRegion: 'Region',
            regionModel: '',
            regionLists: [],
            labelBranch: 'Branc Office',
            officeModel: '',
            officeLists: [],
            labelSales: 'Salesman',
            salesModel: '',
            salesLists: [],
            customerModel: '',
            customerLists: [],
            categoryGroupModel: '',
            categoryGroupLists: [],
            categoryModel: '',
            categoryLists: [],
            materialModel: '',
            materialLists: [],
            gradeModel: '',
            gradeLists: [],
            qualityModel: '',
            qualityLists: [],
            colorModel: '',
            colorLists: [],
            thicknessModel: '',
            thicknessLists: [],
            widthModel: '',
            widthLists: [],
            disabled_region: false,
            disabled_office: false,
            disabled_salesman: false,
            itemLists: [],
            headers: [
                { text: 'ORD. NO', value: 'CustomerOrderNo', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ORD. DATE', value: 'tanggalPesan', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORD. RECEIVED', value: 'tanggaldt_rcv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'NamaCustomer', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'NamaSales', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DESC.', value: 'NamaBarang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'MATERIAL', value: 'jenis', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'THICKNESS(mm)', value: 'Tebal', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WIDTH(mm)', value: 'matrl_width', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'COLOR', value: 'NamaWarna', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'GRADE', value: 'grd', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'QUALITY', value: 'grade', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH(M)', value: 'panjang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'QTY.', value: 'qty', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL', value: 'total', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT(KG)', value: 'berat', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            headersColumn: {
            'ORD. NO':'CustomerOrderNo',
            'ORD. DATE' : 'tanggalPesan',
            'ORD. RECEIVED': 'tanggaldt_rcv',
            'CUSTOMER': 'NamaCustomer',
            'SALESMAN' : 'NamaSales',
            'DESC': 'NamaBarang',
            'MATERIAL': 'jenis',
            'THICKNESS': 'Tebal',
            'WIDTH': 'matrl_width',
            'COLOR': 'NamaWarna',
            'GRADE': 'grd',
            'QUALITY': 'grade',
            'LENGTH' :'panjang',
            'QTY' :'qty',
            'TOTAL' : 'total',
            'WGT' : 'berat',
            },
            loadingDatatable: false,
            searchItem: '',
            userGroup: null,
            appl_id: 'E-REPORTS'
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardResult").style.display = "none";
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.millLists = res.data.mill

                if(res.data.type == 'R'){

                        this.typeModel = 'PROJECT'
                        this.typeLists = [
                            {
                                value: 'PROJECT',
                                text: 'PROJECT'
                            }
                        ]

                } else {

                        this.typeLists = [
                            {
                                value: '',
                                text: 'All'
                            },
                            {
                                value: 'RETAIL',
                                text: 'RETAIL'
                            },
                            {
                                value: 'PROJECT',
                                text: 'PROJECT'
                            },
                        ]

                }

                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                }
                this.materialLists = res.data.material
                this.gradeLists = res.data.grade
                this.qualityLists = res.data.quality
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.categoryGroupLists = res.data.category
                this.categoryLists = res.data.category
                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },
        startDownload(){
        this.loading = true
        this.disabledExport=true
        },
        finishDownload(){
            this.loading = false
            this.disabledExport=false
        },
        async exportExcel() {
            this.$store.dispatch('setOverlay', true)
            const response = await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/ExExcel?mill_id=${this.millModel ? this.millModel : ''}&category=${this.categoryModel ? this.categoryModel : ''}&categoryGroup=${this.categoryGroupModel ? this.categoryGroupModel : ''}&color=${this.colorModel ? this.colorModel : ''}&customer=${this.customerModel ? this.customerModel : ''}&grade=${this.gradeModel ? this.gradeModel : ''}&material=${this.materialModel ? this.materialModel : ''}&office=${this.officeModel ? this.officeModel : ''}&quality=${this.widthModel ? this.widthModel : ''}&region=${this.regionModel ? this.regionModel : ''}&sales=${this.salesModel ? this.salesModel : ''}&thickness=${this.thicknessModel ? this.thicknessModel : ''}&type=${this.typeModel ? this.typeModel : ''}&matrl_width=${this.widthModel ? this.widthModel : ''}`, {
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
            });
             console.log(response.data);
             this.$store.dispatch('setOverlay', false)
             return response.data.result
        },

        async getTotal(){
        this.$store.dispatch('setOverlay', true)
        await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/Total?mill_id=${this.millModel ? this.millModel : ''}&category=${this.categoryModel ? this.categoryModel : ''}&categoryGroup=${this.categoryGroupModel ? this.categoryGroupModel : ''}&color=${this.colorModel ? this.colorModel : ''}&customer=${this.customerModel ? this.customerModel : ''}&grade=${this.gradeModel ? this.gradeModel : ''}&material=${this.materialModel ? this.materialModel : ''}&office=${this.officeModel ? this.officeModel : ''}&quality=${this.widthModel ? this.widthModel : ''}&region=${this.regionModel ? this.regionModel : ''}&sales=${this.salesModel ? this.salesModel : ''}&thickness=${this.thicknessModel ? this.thicknessModel : ''}&type=${this.typeModel ? this.typeModel : ''}&matrl_width=${this.widthModel ? this.widthModel : ''}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                this.$store.dispatch('setOverlay', false)
                console.log(res.data);
                this.totalWgt = res.data[0].total_wgt; 
                })
                .catch(err => {
                    console.error(err);
                });
            },
        async getData(){
            this.getTotal()
            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'warning',
                    })
                return false
            }

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/getData`, { 

                    mill: this.millModel ? this.millModel : '',
                    type: this.typeModel ? this.typeModel : '',
                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    customer: this.customerModel ? this.customerModel : '',
                    categoryGroup: this.categoryGroupModel ? this.categoryGroupModel : '',
                    category: this.categoryModel ? this.categoryModel : '',
                    material: this.materialModel ? this.materialModel : '',
                    grade: this.gradeModel ? this.gradeModel : '',
                    quality: this.qualityModel ? this.qualityModel : '',
                    color: this.colorModel ? this.colorModel : '',
                    thickness: this.thicknessModel ? this.thicknessModel : '',
                    width: this.widthModel ? this.widthModel : '',

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async regionOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/regionOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.officeLists = res.data.office
                this.labelBranch = 'Branch Office(' + res.data.countOffice + ')'
                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async officeOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/officeOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${this.regionModel ? this.regionModel : ''}&office=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        updateCustomerDropdown()
        {
            var id = $('#customer').val()
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderShortageReport/getCustomer?customer=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    })
                    .then(res => {
                        if(res.data.result.length != 0){
                            this.customerLists = res.data.result
                        } else {
                            Swal.fire({
                                text: 'Customer not found, please try again !',
                                icon: 'warning',
                            })
                        }
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            } else {
                this.customerLists = []
            }
        },

        emptyCustomer(id)
        {
            if(id == null){
                this.customerLists = []
            }
        },

        categoryGroupOnChange(id){

            if(id){

                this.categoryLists = []

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/analysis/getCategoryByGroup?id=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.categoryLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                this.categoryLists = []

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/analysis/getCategoryByGroup?id=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.categoryLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            }

        },

    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

</style>